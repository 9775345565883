<template>
    <div class="mc-main">
        <!-- 数据汇总 -->
        <div class="quarter-div">
            <div class="mc-title">
                <span>数据汇总</span>
            </div>
            <div class="mc-content" style="overflow-y:auto">
                <el-col :span="22" class="data-card">
                    <div class="data-detail">
                        <el-row>
                            机会项目：{{siteTotal}}
                        </el-row>
                        <el-row>
                            <span v-for="(item, index) in statusSiteList" :key="index">{{`${item.StatusName}：${item.Count}  `}}</span>
                            <!-- 进行中：20 观望：20 暂停：40 已开业：20  -->
                        </el-row>
                    </div>
                </el-col>
                <el-col :span="7"  class="data-card" v-for="(item, index) in progressSiteList" :key="index">
                    <div class="data-detail">
                        <el-row>
                            {{item.Count}}
                        </el-row>
                        <el-row>
                            {{`${item.Remark}-${item.ProgressName}`}}
                        </el-row>
                    </div>
                </el-col>
            </div>
        </div>
        <!-- 竞品趋势 -->
        <div class="quarter-div">
            <div class="mc-title">
                <span>竞品趋势</span>
            </div>
            <div class="mc-subopts">
                <el-select v-model="trendYear" placeholder="请选择" size="mini" style="width:100px;z-index: 9" @change="portal">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="mc-content">
                <el-radio-group v-model="changeType" size="mini" @change="initEchart" class="cc-select">
                    <el-radio-button :label="'weekly'" >周</el-radio-button>
                    <el-radio-button :label="'monthly'">月</el-radio-button>
                    <el-radio-button :label="'quarterly'">季</el-radio-button>
                </el-radio-group>
                <div ref="compititer" style="width: 100%;height: 94%;background: #fff;margin-top:4px;"></div>
            </div>
        </div>
        <!-- 年度开店计划达成 -->
        <div class="quarter-div" v-show="false">
            <div class="mc-title">
                <span>年度开店计划达成</span>
            </div>
            <div class="mc-subopts">
                <el-select v-model="targetYear" placeholder="请选择" size="mini" style="width:100px;margin-right: 10px;" @change="portal">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="value" placeholder="请选择" size="mini" style="width:100px;">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="mc-content" style="padding-right:7%">
                <el-table
                    :data="tableData"
                    height="91%"
                    border
                    style="width: 100%">
                    <el-table-column
                        prop="city"
                        label="城市">
                    </el-table-column>
                    <el-table-column
                        prop="plan"
                        label="计划开店数">
                    </el-table-column>
                    <el-table-column
                        prop="store"
                        label="已开店数">
                    </el-table-column>
                    <el-table-column
                        prop="pp"
                        label="机会项目数">
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 竞品城市对比 -->
        <div class="quarter-div" v-show="false">
            <div class="mc-title">
                <span>竞品城市对比</span>
            </div>
            <div class="mc-subopts">
                <el-select v-model="value" placeholder="请选择" size="mini" style="width:100px;">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="mc-content">
                <el-table
                    :data="tableData"
                    height="91%"
                    border
                    style="width: 100%">
                    <el-table-column
                        prop="city"
                        label="城市">
                    </el-table-column>
                    <el-table-column
                        prop="plan"
                        label="我司品牌">
                    </el-table-column>
                    <el-table-column
                        prop="store"
                        label="麦当劳">
                    </el-table-column>
                    <el-table-column
                        prop="pp"
                        label="门店差异">
                    </el-table-column>
                    <el-table-column
                        label="门店对比比例">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
import constants from "@/constants/Map.constants.js";
import api from "@/api/index.js";
export default {
    components:{
    },
    data(){
        return {
            options: [{
                value: '2019',
                label: '2019年'
            }, {
                value: '2020',
                label: '2020年'
            }],
            tableData: [{
                city: '上海',
                plan: '100',
                store: '10',
                pp: '56'
            }, {
                city: '上海',
                plan: '100',
                store: '10',
                pp: '56'
            }, {
                city: '上海',
                plan: '100',
                store: '10',
                pp: '56'
            }, {
                city: '上海',
                plan: '100',
                store: '10',
                pp: '56'
            }, {
                city: '上海',
                plan: '100',
                store: '10',
                pp: '56'
            }, {
                city: '上海',
                plan: '100',
                store: '10',
                pp: '56'
            }, {
                city: '上海',
                plan: '100',
                store: '10',
                pp: '56'
            }],
            value: "2020",
            diffBrand: "", // 竞品对比品牌
            targetBrand: "", // 计划达成品牌
            targetYear: "2020", // 计划达成年份
            trendYear: "2020", // 竞品趋势年份
            statusSiteList: [], 
            siteTotal: 0,
            progressSiteList: [],
            brandTrendList: [],
            changeType: "weekly"
        };
    },
    mounted(){
        this.portal();
    },
    methods: {
        // 初始化数据
        portal() {
            api.portal({
                cusNo: sessionStorage.cusNo,
                diffBrand: this.diffBrand, // 竞品对比品牌
                targetBrand: this.targetBrand, // 计划达成品牌
                targetYear: this.targetYear, // 计划达成年份
                trendYear: this.trendYear // 竞品趋势年份
            }).then(res => {
                if(res.code == 200) {
                    this.statusSiteList = res.data.statusSiteList;
                    this.siteTotal = res.data.siteTotal;
                    this.progressSiteList = res.data.progressSiteList;
                    this.brandTrendList = res.data.brandTrendList;
                    this.initEchart(this.changeType);
                }
            })
        },
        initEchart(val) {
            let brandTrendList = [];
            let legendData = []; // 所有品牌
            let xAxisData = []; // x轴日期
            let series = []; // 数据
            this.brandTrendList.map(brand => {
                legendData.push(brand.name);
                let item = JSON.parse(brand[val]);
                let serie = {
                    name: brand.name,
                    type: 'line',
                    smooth: true, // 折线光滑
                    data: []
                };
                if(xAxisData.length == 0) {
                    for(let key in item) {
                        xAxisData.push(key);
                    }
                }
                for(let key in item) {
                    serie.data.push(item[key]);
                }
                series.push(serie);
            });
            let trend = this.$refs.compititer;
            let trendChart = this.echarts.init(trend);
            let option = {
                title: {
                    left: 'center',
                    align: 'right',
                    text: '竞品开店趋势'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    x: "center",
                    y: "bottom",
                    itemWidth: 6,
                    itemHeight: 6,
                    itemGap: 20,
                    data: legendData
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '20%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    min: function(value){
                        return value.min;
                    }
                },
                series
            };
            trendChart.setOption(option);
        }
    }
}

    
</script>
<style >
.mc-main{
    width: 98%;
    background: #F7F6FB;
    height: 94%;
    padding: 1%;
}
.quarter-div {
    width: 50%;
    height: 50%; /* %;100 */
    float: left;
}
.mc-title {
    display: inline-block;
    min-width: 100px;
    background: #0188e2;
    text-align: center;
    border-radius: 3px;
    color: #fff;
    padding: 5px 10px;
    font-size: 13px;
}
.mc-subopts {
    display: inline-block;
    float: right;
    margin-right: 7%;
}
.mc-content {
    height: 90%;
}
.data-card {
    border: 1px solid #fafafc;
    border-radius: 5px;
    /* width: 99%; */
    height: 28%;
    text-align: center;
    margin: 0.5% 1.05%;
    position: relative;
    background: #fff;

}
.data-detail {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
.data-detail>.el-row:nth-child(1) {
    margin-bottom: 20px;
}
.cc-select {
    position: absolute;
    right: 3%;
    margin-top: 4px;
    z-index: 10;
}
</style>